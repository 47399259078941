import React from "react";
import maestro from "../../assets/shop/maestro.svg";
import mastercard from "../../assets/shop/mastercard.svg";
import visa from "../../assets/shop/visa.svg";
interface PaymentIconProps {
  id: "visa" | "mastercard" | "maestro";
  style?: React.CSSProperties;
  className?: string;
}

const PaymentIcon: React.FC<PaymentIconProps> = ({ id, style, className }) => {
  const getIcon = () => {
    switch (id) {
      case "visa":
        return <img style={{ width: "100%" }} src={visa} alt="visa" />;
      case "mastercard":
        return (
          <img style={{ width: "100%" }} src={mastercard} alt="mastercard" />
        );
      case "maestro":
        return <img style={{ width: "100%" }} src={maestro} alt="maestro" />;
      default:
        return null;
    }
  };

  return (
    <div style={style} className={className}>
      {getIcon()}
    </div>
  );
};

export default PaymentIcon;
