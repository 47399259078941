import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";

interface StripeLoaderProps {
  children: React.ReactNode;
}

const StripeLoader = ({ children }: StripeLoaderProps) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    import("@stripe/stripe-js").then((stripe) => {
      const sPromise = stripe.loadStripe(
        "pk_live_Ppf3cv6CA6APXoOmyzTcNFCw00r694DeJd"
      );
      setStripePromise(sPromise);
    });

    const googleMapsScript = document.createElement("script");
    googleMapsScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDSlINdQcMAgQQ1TAOLV2IdUHYROtOsa9U&libraries=places"; // your script URL
    googleMapsScript.type = "text/javascript";

    document.head.appendChild(googleMapsScript);

    return () => {
      document.head.removeChild(googleMapsScript);
    };
  }, []);

  if (!stripePromise) {
    return <></>;
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeLoader;
