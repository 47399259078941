import { observable, computed, action } from "mobx";
import StrapiHelper from "../services/StrapiHelper.service";
import ShopItem from "../models/ShopItem";
import OrderPart from "../models/OrderPart";
import Order from "../models/Order";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Product from "../models/Product";
export default class ShopStore {
  @observable
  shopItems: Product[] = [];

  @observable
  instantPrices: Product[] = [];

  @observable
  currentOrderPart: OrderPart | undefined;

  @observable
  shoppingCart: OrderPart[] = [];

  @observable
  currentShopItem?: Product;

  @observable
  loaded: boolean = false;

  @observable
  currentOrder?: Order | undefined;

  @observable
  orderNumber?: string;

  @observable
  loading: boolean = false;

  @observable
  myOrders?: any[];

  @observable
  error?: any;

  @observable
  showPaymentDialog: boolean = false;

  @observable
  clientSecret: string = "";

  @observable
  setCurrentClientSecret(clientSecret: string) {
    this.clientSecret = clientSecret;
  }

  @observable
  pickup: boolean = false;

  @action
  setPickUp(pickup: boolean) {
    this.pickup = pickup;
  }

  @observable
  personalMessage: string = "";

  @action
  setPersonalMessage(message: string) {
    this.personalMessage = message;
  }

  @action
  setCurrentOrderPart(orderpart: OrderPart) {
    this.currentOrderPart = orderpart;
  }

  @action
  async getShipmentCosts() {
    if (
      !this.shoppingCart ||
      (this.shoppingCart && this.shoppingCart.length == 0)
    ) {
      return 0;
    }
    try {
      const response = await StrapiHelper.getShipmentCost(this.shoppingCart);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {}
    return 0;
  }

  @action
  clearCurrentOrderPart() {
    this.currentOrderPart = undefined;
  }

  @action
  async loadProductGroups(instantPriceOnly?: boolean) {
    let res = await StrapiHelper.loadProductGroups(instantPriceOnly);

    let productGroups = (res.data as Product[]).sort(function (l, r) {
      return (r.priority ?? 0) - (l.priority ?? 0);
    });

    if (instantPriceOnly) {
      this.instantPrices = productGroups;
    } else {
      this.shopItems = productGroups;
    }
  }

  @action
  async loadOrders() {
    try {
      let res = await StrapiHelper.loadOrders();
      if (res && res.status === 200) {
        this.myOrders = res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  @action
  setCurrentOrder(order: Order | undefined) {
    this.currentOrder = order;
  }

  @action
  clearShoppingCard() {
    this.shoppingCart = [];
    this.clearLocalStorage();
  }

  @action
  hidePaymentDialog() {
    this.showPaymentDialog = false;
  }

  @action
  displayPaymentDialog() {
    this.showPaymentDialog = true;
  }
  @action
  async createIntend(order: Order) {
    this.startLoading();
    try {
      let res = await StrapiHelper.createIntend(order);
      if (res && res.data && res.data.clientSecret) {
        this.showPaymentDialog = true;
        this.clientSecret = res.data.clientSecret;
        this.orderNumber = res.data.ordernumber;
      }
    } catch (exception) {
      this.stopLoading();
      return exception;
    }
    this.stopLoading();
  }

  @action
  setOrderNumber(number: string) {
    this.orderNumber = number;
  }

  @action
  setCurrentShopItem(event?: Product) {
    this.currentShopItem = event;
  }

  @action
  startLoading() {
    this.loading = true;
  }

  @action
  stopLoading() {
    this.loading = false;
  }

  @action
  async loadCurrentShopItem(id: number) {
    this.startLoading();
    this.currentShopItem = undefined;
    const response = await StrapiHelper.getOneProduct(id);
    // await this.loadProductGroups();
    if (response && response.status === 200) {
      this.currentShopItem = response.data;
    }
    this.stopLoading();
  }

  @action
  addItemToShoppingCart(item: OrderPart, noMerge?: boolean) {
    if (noMerge) {
      this.shoppingCart.push(item);
      this.storeToLocalStorage(item);
    } else {
      let found = false;
      //@ts-ignore
      if (item.product.productType != "CUSTOM_TRIKOT") {
        for (let i = 0; i < this.shoppingCart.length; i++) {
          let orderPart = this.shoppingCart[i];
          //@ts-ignore
          if (
            orderPart.product.id === item.product.id &&
            orderPart.option === item.option &&
            orderPart.option2 === item.option2
          ) {
            let itemAmount = 0;
            let orderAmount = orderPart.amount;
            if (item.amount) {
              itemAmount = item.amount;
            }
            if (!orderAmount) {
              orderAmount = 0;
            }
            orderPart.amount = orderAmount + itemAmount;
            found = true;
          }
        }
      }
      if (!found) {
        this.shoppingCart.push(item);
        this.storeToLocalStorage(item);
      } else {
        this.updateLocalStorage(this.shoppingCart);
      }
    }
  }

  @action
  getNumberOfCartItems() {
    var numberOfItems = 0;
    this.shoppingCart.forEach(
      (orderPart) => (numberOfItems += orderPart.amount!)
    );
    return numberOfItems;
  }

  updateLocalStorage(orderParts: OrderPart[]) {
    localStorage.setItem("ShoppingCart", JSON.stringify(orderParts));
  }

  @action
  loadShopCardItems() {
    const localShoppingCart = localStorage.getItem("ShoppingCart");
    if (localShoppingCart) {
      let items = JSON.parse(localShoppingCart) as OrderPart[];
      this.shoppingCart = items;
    }
  }

  @action
  async addCustomImageToOrderAndStore(orderPart: OrderPart, image: any) {
    this.startLoading();
    if (this.currentOrderPart) {
      const result = await StrapiHelper.removeBackground(image, true);

      if (!result || (result && !result.data)) {
        // const imageObj = response.data[0];
      }

      const uploadImage: any = await StrapiHelper.uploadImage(result.data);
      const imageObj = uploadImage.data[0];

      //@ts-ignore
      this.currentOrderPart.image = imageObj;
      this.addItemToShoppingCart(this.currentOrderPart, true);
      this.currentOrderPart = undefined;
    }
    this.stopLoading();
  }

  @action
  clearErrer() {
    this.error = undefined;
  }

  @action
  async checkAvailablity() {
    return true;
    return new Promise((resolve, reject) => {
      var available = true;
      this.shoppingCart.forEach((orderPart) => {
        if (orderPart.amount) {
          const product = this.getProductById(orderPart.product.id);
          if (orderPart.amount > product.stock) {
            available = false;
          }
        }
      });
      if (available) {
        resolve(null);
      } else {
        reject();
      }
    });
  }

  // return new Promise((resolve, reject) => {
  //   return StrapiHelper.checkAvailablity(this.shoppingCart)
  //     .then((result) => {
  //       this.loading = false;
  //       resolve(result);
  //     })
  //     .catch((err) => {
  //       this.loading = false;
  //       if (err && err.response && err.response.data.status == 303) {
  //         this.error = {
  //           status: 303,
  //           msg:
  //             "Das Produkt " +
  //             err.response.data.message.Name +
  //             " ist leider bereits ausverkauft.",
  //         };
  //       } else {
  //       }
  //       reject();
  //     });
  // });

  @action
  getProductById(id: any, instantPriceOnly?: boolean) {
    if (instantPriceOnly) {
      return this.instantPrices.filter((item) => {
        return item.id === id;
      })[0];
    }
    return this.shopItems.filter((item) => {
      return item.id === id;
    })[0];
  }

  @action
  deleteItemFromShopCard(item: OrderPart) {
    const localShoppingCart = localStorage.getItem("ShoppingCart");

    if (localShoppingCart) {
      let newShopCard: OrderPart[] = [];
      let localShopItems = JSON.parse(localShoppingCart) as OrderPart[];
      for (let i = 0; i < localShopItems.length; i++) {
        if (localShopItems[i].id != item.id) {
          newShopCard.push(localShopItems[i]);
        }
      }
      localStorage.setItem("ShoppingCart", JSON.stringify(newShopCard));
      this.shoppingCart = newShopCard;
    }
  }

  storeToLocalStorage(item: OrderPart) {
    const localShoppingCart = localStorage.getItem("ShoppingCart");
    let shopItems: OrderPart[] = [];
    if (!localShoppingCart) {
      shopItems.push(item);
    }
    if (localShoppingCart) {
      shopItems = JSON.parse(localShoppingCart);
      shopItems.push(item);
    }
    localStorage.setItem("ShoppingCart", JSON.stringify(shopItems));
  }

  clearLocalStorage() {
    localStorage.setItem("ShoppingCart", JSON.stringify([]));
  }
}
